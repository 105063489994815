export const useAllDKRolesAndPrivilegesStore = defineStore("allDKRolesAndPrivilegesStore", {
    state: () => ({
        allDKRolesAndPrivileges: null,
    }),
    getters: {
        getAllDKRolesAndPrivileges() {
            return this.allDKRolesAndPrivileges;
        },
    },
    actions: {
        setAllDKRolesAndPrivileges(info) {
            this.allDKRolesAndPrivileges = info
        },
    }
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useAllDKRolesAndPrivilegesStore, import.meta.hot));
}