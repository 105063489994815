export const useUserDKRolesStore = defineStore("userDKRolesStore", {
    state: () => ({
        userDKRoles: null,
    }),
    getters: {
        getUserDKRoles() {
            return this.userDKRoles;
        },
    },
    actions: {
        setUserDKRoles(info) {
            this.userDKRoles = info
        },
    }
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useUserDKRolesStore, import.meta.hot));
}